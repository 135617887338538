@import '~@density/dust/dist/tokens/dust.tokens';

.loading {
  width: 100%;
  margin-top: 64px;
  text-align: center;
  font-size: 18px;
  color: $gray-200;
}

.container {
  margin: 4em auto;
  width: 600px;
}

.button {
  margin: 2em 0;
  text-align: right;
}

.gridButton {
  text-align: right;
}

.dialogOptionTitle {
  margin: 0.5em 0;
  font-size: 1.1em;
}

.loadingLogoWrapper {
  margin-top: 64px;
  display: flex;
  justify-content: center;
}

.densityLogo {
  width: 60px;
  margin: 3em;
  animation: loader-image-spin infinite 2s linear;
}

@keyframes loader-image-spin {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(360deg);
  }
}